import { Maybe } from 'graphql/jsutils/Maybe';
import Image from 'next/image';
import { HTMLAttributes, useState } from 'react';

import { CurrentUser, useCurrentUser } from '@/auth/useCurrentUser';

import Text from '@/components/data-display/Text';

import SmartLink from '@/components/navigation/SmartLink';

import { User } from '@/generated/graphql';
import { classNames } from '@/utils/classNames';

import SVG from './SVG';

interface UserAvatarProps extends HTMLAttributes<HTMLDivElement> {
  href?: string;
  user?: Maybe<User> | CurrentUser;
  size?: string | number;
}

interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
  avatarLetters?: string;
  src?: string;
  size?: string | number;
}

interface UserAvatarLetters extends HTMLAttributes<HTMLDivElement> {
  letters?: string;
  size?: string | number;
}

const formatSize = (size: string | number): string => {
  const strSize = `${size}`;
  return strSize.includes('px')
    ? `${Number(strSize.replace('px', '')) * 2}px`
    : `${Number(size) * 2}px`;
};

const UserAvatarLetters = ({
  className,
  letters,
  size = '48px',
  ...rest
}: UserAvatarLetters) => {
  return (
    <div
      className={classNames('items-center flex justify-center', className)}
      style={{ height: size, width: size }}
      {...rest}
    >
      {letters && <Text variant="h5">{letters}</Text>}
      {!letters && <SVG icon="sign-in" size="lg" />}
    </div>
  );
};

const Avatar = ({
  avatarLetters,
  children,
  size = '48px',
  src = '',
  ...rest
}: AvatarProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <>
      {(!src || !isImageLoaded) && (
        <UserAvatarLetters
          data-testid="user-avatar-letter"
          letters={avatarLetters}
          size={size}
          {...rest}
          className="rounded-full border-2 aspect-square"
        />
      )}
      {src && (
        // This additional <div> below is used to prevent <Image> from being reduced in size due to border thickness when the `fill` property is present
        <div
          style={{ height: size, width: size }}
          className={classNames(
            'rounded-full z-0 overflow-hidden',
            isImageLoaded && 'relative rounded z-0',
            !isImageLoaded && 'absolute opacity-0'
          )}
        >
          <div
            data-testid="user-avatar-pic"
            style={{ height: size, width: size }}
            {...rest}
            className="rounded-full border-2 aspect-square"
          >
            <Image
              className="object-cover -z-10"
              src={src}
              alt="user-avatar-pic"
              sizes={formatSize(size)}
              fill
              onLoad={() => setIsImageLoaded(true)}
            />

            {children}
          </div>
        </div>
      )}
    </>
  );
};

const UserAvatar = ({ className, href, user, ...rest }: UserAvatarProps) => {
  const currentUser = useCurrentUser();
  const userWithFallbackCurrent = user || currentUser;

  const avatarLetters = userWithFallbackCurrent?.firstName?.charAt(0);

  const src = userWithFallbackCurrent?.profilePhoto?.src?.publicUrlTransformed;

  const avatarProps = {
    avatarLetters,
    className,
    src: src,
    ...rest,
  };

  if (href) {
    return (
      <SmartLink href={href}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Avatar {...avatarProps} />;
      </SmartLink>
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Avatar {...avatarProps} />;
};

export default UserAvatar;
