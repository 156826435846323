import { useCurrentUser } from '@/auth/useCurrentUser';
import Text from '@/components/data-display/Text';
import FullButton from '@/components/data-entry/FullButton';

// TODO: Improve render logic - consider dynamic parent wrapper component based on route.
export const WithAccessControl = ({ children, router }) => {
  const currentUser = useCurrentUser();

  const isCompanyRoute = router.asPath.startsWith('/company/');
  const isVerified = currentUser?.doctor?.isVerified;

  // TODO: Improve - consider fetching membership for selected company.
  const isMemberSomewhere = Boolean(currentUser?.membershipsCount);
  const canAccessDoctorDashboard = isMemberSomewhere || isVerified;

  const serverError = currentUser?.error?.networkError?.statusCode >= 500;

  if (
    !serverError &&
    !currentUser.loading &&
    isCompanyRoute &&
    !canAccessDoctorDashboard
  ) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <Text dataTestId="feedBack-unverified-doctor" variant="h2">
            Verification in progress.
          </Text>
          <div>Your doctor profile is being verified or is restricted.</div>

          <div className="mt-7 flex gap-4 flex-wrap justify-center items-center">
            <FullButton href="/">Patient Dashboard</FullButton>

            <FullButton
              variant="outline"
              href="mailto:team@pocketmd.ca?subject=Doctor verification inquiry"
            >
              Contact Us
            </FullButton>
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
