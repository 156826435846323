/* eslint-disable sort-keys */
import Link from 'next/link';
import { LinkHTMLAttributes, forwardRef, Ref } from 'react';

import SVG, { PredefinedIconsTypes } from '@/components/data-display/SVG';

import { CompanyLink } from '@/components/navigation/CompanyLink';

import { COMPANY_ROOT_PATH } from '@/lib/constants';

import { Size, ColorScheme } from '@/types/index';

import { classNames } from '@/utils/classNames';

export const BTN_CLASSES = {
  solid: {
    primary: 'bg-primary text-surface hover:bg-primary/75',
    secondary: 'bg-secondary text-surface hover:bg-secondary/75',
    surface: 'bg-surface text-primary',
    accent: 'bg-accent text-surface hover:bg-accent/75',
    ghost: 'bg-ghost text-primary hover:bg-primary/25',
    info: 'bg-info text-surface hover:bg-info/75',
    success: 'bg-success text-surface hover:bg-success/75',
    warning: 'bg-warning text-primary hover:bg-warning/75',
    error: 'bg-error text-surface hover:bg-error/75',
  },
  outline: {
    primary: 'hover:bg-primary hover:text-surface border text-primary',
    secondary: 'hover:bg-secondary hover:text-surface border text-secondary',
    accent: 'hover:bg-accent hover:text-surface border text-accent',
    info: 'hover:bg-info hover:text-surface border text-info',
    success: 'hover:bg-success hover:text-surface border text-success',
    warning: 'hover:bg-warning hover:text-primary border text-warning',
    error: 'hover:bg-error hover:text-surface border text-error',
  },
  ghost: {
    primary: 'bg-ghost text-primary hover:bg-primary/25',
    secondary: 'bg-ghost text-primary hover:bg-primary/25',
    accent: 'bg-ghost text-primary hover:bg-primary/25',
    info: 'bg-ghost text-primary hover:bg-primary/25',
    success: 'bg-ghost text-primary hover:bg-primary/25',
    warning: 'bg-ghost text-primary hover:bg-primary/25',
    error: 'bg-ghost text-primary hover:bg-primary/25',
  },
  link: {
    primary: 'text-primary underline',
    secondary: 'text-primary underline',
    accent: 'text-primary underline',
    info: 'text-primary underline',
    success: 'text-primary underline',
    warning: 'text-primary underline',
    error: 'text-primary underline',
  },
  sizes: {
    base: `text-sm px-4 h-8 min-h-max`,
    tiny: `text-xs px-4 h-6 min-h-6`,
    xs: `text-xs px-4 h-6 min-h-6`,
    sm: `text-sm px-4 h-7 min-h-max`,
    md: `text-sm px-4 h-8 min-h-max`,
    lg: `text-lg px-6 h-10 min-h-max`,
    xl: `text-xl px-8 h-12 min-h-max`,
  },
  square: {
    base: 'text-sm h-8 w-8',
    tiny: 'text-xs h-6 w-6',
    xs: 'text-xs h-6 w-6',
    sm: 'text-sm h-7 w-7',
    md: 'text-sm h-8 w-8',
    lg: 'text-lg h-10 w-10',
    xl: 'text-xl h-12 w-12',
  },
  circle: {
    base: 'rounded-full text-sm h-8 w-8',
    tiny: 'rounded-full text-xs h-6 w-6',
    xs: 'rounded-full text-xs h-6 w-6',
    sm: 'rounded-full text-sm h-7 w-7',
    md: 'rounded-full text-sm h-8 w-8',
    lg: 'rounded-full text-lg h-10 w-10',
    xl: 'rounded-full text-xl h-12 w-12',
  },
};

type BtnVariants = keyof typeof BTN_CLASSES;

export interface FullButtonProps
  extends Partial<LinkHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>> {
  block?: boolean;
  colorScheme?: ColorScheme;
  disabled?: boolean;
  download?: string | boolean;
  endIcon?: PredefinedIconsTypes | JSX.Element;
  fillClassName?: string;
  form?: string;
  icon?: PredefinedIconsTypes | JSX.Element;
  loading?: boolean;
  loadingText?: string;
  shape?: 'circle' | 'square' | 'block' | 'round';
  size?: Size;
  startIcon?: PredefinedIconsTypes | JSX.Element;
  target?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: BtnVariants;
}

const FullButton = forwardRef((props: FullButtonProps, propRef) => {
  const {
    block,
    children,
    className: providedClassName,
    colorScheme = 'primary',
    disabled,
    fillClassName,
    href,
    loading,
    loadingText,
    icon,
    endIcon,
    startIcon,
    shape,
    target,
    type = 'submit',
    size = 'base',
    variant = 'solid',
    ...rest
  } = props;

  const buttonShapeStyles = classNames(
    shape === 'circle' && BTN_CLASSES.circle[size],
    shape === 'square' && BTN_CLASSES.square[size],
    shape === 'block' && 'w-full flex-1'
  );
  const buttonSizeStyles =
    shape !== 'circle' && shape !== 'square' ? BTN_CLASSES.sizes[size] : '';

  const startSvgClassNames = classNames(
    fillClassName || `fill-current`,
    loading && 'animate-spin'
  );

  const variantClasses = BTN_CLASSES[variant] || BTN_CLASSES.solid;
  const buttonColorSchemeClassNames =
    variantClasses?.[colorScheme || 'primary'] || BTN_CLASSES.solid.primary;

  const variantClassName = classNames(
    'capitalize font-semibold inline-flex items-center justify-center rounded ',
    'transition-all btn gap-2 group',
    buttonColorSchemeClassNames,
    block && 'w-full',
    buttonSizeStyles,
    buttonShapeStyles,
    'no-animation',
    providedClassName,
    shape === 'round' && 'rounded-full aspect-square'
  );

  const renderButtonContent = () => {
    return (
      <>
        {(loading || startIcon) && (
          <SVG
            aria-hidden="true"
            className={startSvgClassNames}
            data-testid="loading-spinner"
            // @ts-expect-error - Needs to be fixed
            icon={loading ? 'loading' : startIcon}
            size={size}
          />
        )}

        {!loading && icon && (
          <SVG size={size} icon={icon} className={startSvgClassNames} />
        )}

        {loading && loadingText ? loadingText : children}

        {endIcon && (
          <SVG
            aria-hidden="true"
            className={startSvgClassNames}
            icon={endIcon}
            size={size}
          />
        )}
      </>
    );
  };

  if (disabled) {
    return (
      <button
        {...rest}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={propRef}
        className={classNames(
          variantClassName,
          'grayscale opacity-50 cursor-not-allowed'
        )}
        type={type}
        disabled={disabled}
      >
        {renderButtonContent()}
      </button>
    );
  }

  if (href) {
    if (target === '_blank' || rest.download) {
      return (
        <a
          {...rest}
          className={variantClassName}
          href={href}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={propRef}
          rel="noreferrer noopener"
          target={rest.download ? '' : target}
        >
          {renderButtonContent()}
        </a>
      );
    } else {
      return (
        <>
          {href.startsWith(COMPANY_ROOT_PATH) ? (
            <CompanyLink
              {...rest}
              className={variantClassName}
              href={href}
              ref={propRef as Ref<HTMLAnchorElement>}
              target={target}
            >
              {children}
            </CompanyLink>
          ) : (
            <Link
              {...rest}
              className={variantClassName}
              href={href}
              ref={propRef as Ref<HTMLAnchorElement>}
              target={target}
            >
              {renderButtonContent()}
            </Link>
          )}
        </>
      );
    }
  }

  return (
    <button
      {...rest}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={propRef}
      className={variantClassName}
      type={type}
    >
      {renderButtonContent()}
    </button>
  );
});

FullButton.displayName = 'FullButton';

export default FullButton;
