import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

import Text from '@/components/data-display/Text';

import FullButton from '@/components/data-entry/FullButton';

import SmartLink from '@/components/navigation/SmartLink';

import { useCurrentUserLazyQuery } from '@/generated/graphql';

import { isUnavailableModalOpenVar } from '@/shared-state/common/feedback';

import Modal from './Modal';

export const UnavailableModal = () => {
  const isOpen = useReactiveVar(isUnavailableModalOpenVar);

  const [fetchCurrentUser, { data, stopPolling }] = useCurrentUserLazyQuery({});

  useEffect(() => {
    if (isOpen) {
      try {
        fetchCurrentUser({
          fetchPolicy: 'network-only',
          pollInterval: 2000,
        });
      } catch (e) {
        return;
      }
    } else {
      stopPolling();
    }
  }, [isOpen]);

  useEffect(() => {
    if (data) {
      window.location.reload();
    }
  }, [data]);

  return (
    <Modal
      className="!bg-transparent !w-full !h-full !p-0 !max-h-full !border-none"
      isOpen={isOpen}
      shouldCloseOnBackdropClick={false}
      titleClassName="!hidden"
      widthClassName="!w-[100vw] !h-[100vh] relative"
    >
      <div className="bg-background flex relative w-full h-full">
        <div className="p-8 w-full md:w-4/6 h-screen flex flex-col items-center justify-center gap-4 mx-auto text-center">
          <Text variant="h1">Application Temporarily Unavailable</Text>

          <Text>
            We apologize for the inconvenience. Looks like something went wrong.
            Please try refreshing or trying again in a few minutes.
          </Text>

          <Text>We appreciate your understanding.</Text>

          <div className="flex gap-4 items-center">
            <FullButton type="button" onClick={() => window.location.reload()}>
              Reload
            </FullButton>

            <SmartLink
              href={`mailto:${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}`}
              className="underline"
            >
              Contact Support.
            </SmartLink>
          </div>
        </div>
      </div>
    </Modal>
  );
};
