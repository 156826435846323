import '../styles/global.css';
import { ApolloProvider } from '@apollo/client';
import { Analytics } from '@vercel/analytics/react';

// import Cookies from 'js-cookie';

import dynamic from 'next/dynamic';

import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar';

import { PrimeReactProvider } from 'primereact/api';
import { usePassThrough } from 'primereact/passthrough';
import Tailwind from 'primereact/passthrough/tailwind';

import { Fragment } from 'react';

import { FeedbackModal } from '@/components/feedback/FeedbackModal';
import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { UnavailableModal } from '@/components/feedback/UnavailableModal';
import { RouterContext } from '@/context';
import { usePMDRouter } from '@/hooks/usePMDRouter';

import { DefaultLayout } from '@/layouts/DefaultLayout';

import { DoctorMobileUnavailableLayout } from '@/layouts/DoctorMobileUnavailableLayout';
import { useApollo } from '@/lib/apolloClient';
import { AppErrorBoundary } from '@/lib/AppErrorBoundary';
import { SessionProvider } from '@/lib/auth';
import { COMPANY_ROOT_PATH } from '@/lib/constants';
import { CustomTheme } from '@/lib/CustomTheme';
import GlobalErrorBoundary from '@/lib/GlobalErrorBoundary';
import { WithAccessControl } from '@/lib/WithAccessControl';
import WithPageProps from '@/lib/WithPageProps';
import { WithUserSession } from '@/lib/WithUserSession';

const UserPrompts = dynamic(() => import('@/components/auth/UserPrompts'), {
  ssr: false,
});

const App = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);
  const isTempDoctorMobileDisabled = Component.shouldPreventMobileAccess;
  const InterceptLayout = isTempDoctorMobileDisabled
    ? DoctorMobileUnavailableLayout
    : Fragment;
  const Layout = Component.Layout || DefaultLayout;
  const router = usePMDRouter();

  const CustomTailwindTheme = usePassThrough(Tailwind, CustomTheme, {
    mergeProps: false,
    mergeSections: true,
  });

  return (
    <PrimeReactProvider value={{ pt: CustomTailwindTheme, unstyled: true }}>
      <GlobalErrorBoundary>
        <SessionProvider session={pageProps.session} refetchInterval={0}>
          <ApolloProvider client={apolloClient}>
            <WithUserSession>
              <UnavailableModal />

              <WithPageProps>
                <RouterContext.Provider value={router}>
                  <WithAccessControl router={router}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <UserPrompts>
                      {({ /* prompts, */ currentUser, loading }) => {
                        if (loading) return <LoadingSpinner />;

                        const serverError =
                          currentUser?.error?.networkError?.statusCode >= 500;

                        if (
                          !serverError &&
                          !currentUser.isLoggedIn &&
                          !router.asPath.includes('/login') &&
                          !router.asPath.includes('/unavailable') &&
                          !router.asPath.includes('/access/data')
                        ) {
                          router.push('/login');
                          return <LoadingSpinner />;
                        }

                        if (
                          !serverError &&
                          currentUser?.id &&
                          !currentUser.isOnboarded &&
                          !router.asPath.includes('/onboard/patient')
                        ) {
                          router.push('/onboard/patient');
                          return <LoadingSpinner />;
                        }

                        if (
                          !serverError &&
                          currentUser?.id &&
                          !currentUser.isOnboardedDoctor &&
                          !currentUser.membershipsCount &&
                          (router.asPath.startsWith(`${COMPANY_ROOT_PATH}/`) ||
                            router.asPath.includes('/d/'))
                        ) {
                          router.push('/onboard/doctor');
                          return <LoadingSpinner />;
                        }

                        // const userPrompt = prompts?.length && prompts[0];

                        // @TODO: Handle non-redirect prompts
                        // if (
                        //   userPrompt?.promptValue &&
                        //   !router.asPath.includes(userPrompt.promptValue)
                        // ) {
                        //   router.push(userPrompt.promptValue);

                        //   // This loading spinner ensures that there's a visual loading feedback
                        //   // while the router is redirecting (in case it's slow).
                        //   return <FullScreenLoading />;
                        // }

                        return (
                          <Layout>
                            <FeedbackModal />

                            <AppErrorBoundary>
                              <InterceptLayout>
                                <Component {...pageProps} />
                                <ProgressBar
                                  height="4px"
                                  color="#37585e"
                                  options={{ showSpinner: false }}
                                  shallowRouting
                                />
                                <Analytics />
                              </InterceptLayout>
                            </AppErrorBoundary>
                          </Layout>
                        );
                      }}
                    </UserPrompts>
                  </WithAccessControl>
                </RouterContext.Provider>
              </WithPageProps>
            </WithUserSession>
          </ApolloProvider>
        </SessionProvider>
      </GlobalErrorBoundary>
    </PrimeReactProvider>
  );
};

export default App;
