export function checkBrowser() {
  // Get the user-agent string
  const userAgentString = navigator.userAgent;

  // Detect Chrome
  let isChrome = userAgentString.indexOf('Chrome') > -1;

  // Detect Internet Explorer
  const isIE = userAgentString.indexOf('MSIE') > -1;

  // Detect Firefox
  const isFirefox = userAgentString.indexOf('Firefox') > -1;

  // Detect Safari
  let isSafari = userAgentString.indexOf('Safari') > -1;

  // Discard Safari since it also matches Chrome
  if (isChrome && isSafari) isSafari = false;

  // Detect Opera
  const isOpera = userAgentString.indexOf('OP') > -1;

  // Discard Chrome since it also matches Opera
  if (isChrome && isOpera) isChrome = false;

  return {
    isChrome,
    isFirefox,
    isIE,
    isOpera,
    isSafari,
  };
}

export async function isBrowserTrackingBlocked() {
  const testUrl =
    'https://pocketmd.ca/there_s-always-money-in-the-banana-stand';
  const data = new Blob(['Well done is better than well said.'], {
    type: 'text/plain',
  });

  try {
    const isSent = navigator.sendBeacon(testUrl, data);

    if (isSent) return false;
  } catch (error) {
    console.info(
      `💡 Browser tracking is blocked some features may not work as expected.`
    );
    return true;
  }

  // Fallback in case the beacon API indicates failure, assume tracking is blocked
  return true;
}
